import React, { FC } from "react";
import classnames from "classnames";

import { IMessage } from "../../store/types/message";

import "./message.sass";

interface MessageProps {
    message: IMessage;
    userId: number;
    prevUserId: any;
}

export const Message: FC<MessageProps> = ({
    message: {
        author: { id, username, avatar },
        message,
    },
    userId,
    prevUserId,
}) => {
    return (
        <div
            className="message"
            style={{ marginTop: prevUserId !== id ? "1rem" : "0" }}
        >
            <div className="message__avatar_wrapper">
                {prevUserId !== id ? (
                    avatar ? (
                        <img className="message__avatar" src={avatar} alt="" />
                    ) : (
                        <div className="message__avatar_replacement"></div>
                    )
                ) : null}
            </div>
            <div
                className={classnames("message__content", {
                    message__content_own: userId === id,
                    message__content_wide: message.startsWith("img:"),
                })}
            >
                {prevUserId !== id && (
                    <p className="message__username">{username}</p>
                )}
                {message.startsWith("img:") ? (
                    <img
                        className="message__image"
                        src={message.replace("img:", "")}
                        alt=""
                    />
                ) : message.startsWith("link:") ? (
                    <a
                        className="message__link"
                        target="_blank"
                        rel="noreferrer"
                        href={message.replace("link:", "")}
                    >
                        {message.replace("link:", "")}
                    </a>
                ) : (
                    <p className="message__text">{message}</p>
                )}
            </div>
        </div>
    );
};
