import React, { FC } from "react";

import { Sticker } from "./sticker";

import { ISticker } from "../../store/types/sticker";

import "./stickers.sass";

const stickers: ISticker[] = [
    { id: 1, name: "klass", picture: "klass.webp" },
    { id: 2, name: "clever", picture: "clever.png" },
    { id: 3, name: "hit", picture: "hit.webp" },
    { id: 4, name: "wow", picture: "wow.png" },
    { id: 5, name: "mockery", picture: "mockery.png" },
    { id: 6, name: "wink", picture: "wink.png" },
    { id: 7, name: "stupid", picture: "stupid.png" },
    { id: 8, name: "sad", picture: "sad.png" },
    { id: 9, name: "hmm", picture: "hmm.png" },
    { id: 10, name: "smile", picture: "smile.png" },
    { id: 11, name: "facepalm", picture: "facepalm.png" },
    { id: 12, name: "ha", picture: "ha.png" },
    { id: 13, name: "fuckoff", picture: "fuckoff.png" },
    { id: 14, name: "mda", picture: "mda.png" },
    { id: 15, name: "hello", picture: "hello.png" },
    { id: 16, name: "cool", picture: "cool.png" },
    { id: 17, name: "evil", picture: "evil.png" },
    { id: 18, name: "class", picture: "class.png" },
    { id: 19, name: "laugh", picture: "laugh.webp" },
    { id: 20, name: "oh", picture: "oh.webp" },
    { id: 21, name: "rage", picture: "rage.webp" },
    { id: 22, name: "bigsmile", picture: "bigsmile.webp" },
    { id: 23, name: "cry", picture: "cry.webp" },
    { id: 24, name: "listen", picture: "listen.webp" },
];

interface StickersProps {
    sendMessage: (message: string) => void;
}

export const Stickers: FC<StickersProps> = ({ sendMessage }) => (
    <div className="stickers__block">
        {stickers.map((sticker: ISticker) => (
            <Sticker
                sticker={sticker}
                sendSticker={sendMessage}
                key={sticker.id}
            />
        ))}
    </div>
);
