import React, { FC } from "react";

import { ISticker } from "../../../store/types/sticker";

import "./sticker.sass";

const URL = "https://api.ryletd.ru/files/chat-assets/";

interface StickerProps {
    sticker: ISticker;
    sendSticker: (message: string) => void;
}

export const Sticker: FC<StickerProps> = ({ sticker, sendSticker }) => {
    const onClick = () => sendSticker("img:" + URL + sticker.picture);

    return (
        <button className="sticker" onClick={onClick}>
            <img
                className="sticker__img"
                src={URL + sticker.picture}
                alt={sticker.name}
            />
        </button>
    );
};
