export const encrypt = (data: string, key: number): string =>
    JSON.stringify([...data].map((value) => +value.charCodeAt(0) * +key));

export const decrypt = (data: string, key: number): string =>
    JSON.parse(
        JSON.parse(data)
            .map((value: number) => String.fromCharCode(value / key))
            .join("")
    );

// Преобразование в arraybuffer
export const stringToArrayBuffer = (obj: string) => {
    var buf = new ArrayBuffer(obj.length * 2);
    var bufView = new Uint16Array(buf);
    for (var i = 0, strLen = obj.length; i < strLen; i++) {
        bufView[i] = obj.charCodeAt(i);
    }
    return buf;
};

// Преобразование arraybuffer в строку
export const arrayBufferToString = (buf: ArrayBuffer) =>
    String.fromCharCode.apply(null, [...new Uint16Array(buf)]);
