import React, {
    FC,
    useState,
    MouseEvent,
    KeyboardEvent,
    ChangeEvent,
} from "react";

import "./login.sass";

interface LoginProps {
    loginByInvite: (invite: string) => void;
}

export const Login: FC<LoginProps> = ({ loginByInvite }) => {
    const [value, setValue] = useState<string>("");

    // Отправлка сообщения на сервер
    const sendMessage = () => {
        setValue("");
        loginByInvite(value);
    };

    // Изменение текста в инпуте
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
        setValue(target.value);

    // Клик Enter для отправки сообщения
    const onKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
        if (key === "Enter") if (value) sendMessage();
    };

    // Вставка текста из буфера обмена при правом клике
    const onContextMenu = async (e: MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        const textFromBuffer: string = await navigator.clipboard.readText();
        setValue(textFromBuffer);
    };

    return (
        <div className="login__wrapper">
            <div className="login">
                <h1 className="login__title">Write your invite code</h1>
                <input
                    type="text"
                    className="login__input"
                    placeholder="Write your invite code..."
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onContextMenu={onContextMenu}
                />
            </div>
        </div>
    );
};
