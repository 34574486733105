export const host = "wss://ws.ryletd.ru";

export enum wsEvents {
    GET_ENCRYPTION_KEY = "GET_ENCRYPTION_KEY",
    UPDATE_ENCRYPTION_KEY = "UPDATE_ENCRYPTION_KEY",
    LOGIN = "LOGIN",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    GET_ALL_MESSAGES = "GET_ALL_MESSAGES",
    GET_MESSAGE = "GET_MESSAGE",
    SEND_MESSAGE = "SEND_MESSAGE",
    REMOVE_ALL_MESSAGES = "REMOVE_ALL_MESSAGES",
    REMOVE_USER = "REMOVE_USER",
}
