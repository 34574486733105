import React, { forwardRef, useEffect } from "react";

import { Message } from "../message";

import { IMessage } from "../../store/types/message";

import scrollDownPic from "./assets/scroll-down.png";
import "./messages-list.sass";

interface MessagesListProps {
    connected: boolean;
    messages: IMessage[];
    userId: number;
    getAllMessages: () => void;
    scrollToEnd: () => void;
}

export const MessagesList = forwardRef(
    (
        {
            connected,
            messages,
            userId,
            getAllMessages,
            scrollToEnd,
        }: MessagesListProps,
        ref: any
    ) => {
        useEffect(() => getAllMessages(), [getAllMessages]);

        return (
            <div className="messageslist" ref={ref}>
                <button
                    className="messageslist__scrolldown"
                    onClick={scrollToEnd}
                >
                    <img
                        className="messageslist__scrolldown_img"
                        src={scrollDownPic}
                        alt=""
                    />
                </button>
                {connected ? (
                    <>
                        {messages.length ? (
                            messages.map((message, idx) => (
                                <Message
                                    message={message}
                                    key={message.id}
                                    userId={userId}
                                    prevUserId={messages[idx - 1]?.author.id}
                                />
                            ))
                        ) : (
                            <p className="messageslist__central">
                                Сообщений пока что нету!
                            </p>
                        )}
                    </>
                ) : (
                    <p className="messageslist__central red">
                        Ошибка подключения!
                    </p>
                )}
            </div>
        );
    }
);
