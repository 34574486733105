import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import useSound from "use-sound";

import { MessagesList } from "../messages-list";
import { MessageInput } from "../message-input";
import { Stickers } from "../stickers";
import { Login } from "../login";

import { IMessage } from "../../store/types/message";
import { IUser } from "../../store/types/user";
import { host, wsEvents } from "../../store/ws/events";
import {
    encrypt,
    decrypt,
    stringToArrayBuffer,
    arrayBufferToString,
} from "../../utils/encryption";

import newMessageSound from "./assets/newMessageSound.mp3";
import "./app.sass";

export const App: FC = () => {
    const [key, setKey] = useState<number>(0);
    // Пользователь
    const [user, setUser] = useState<IUser | null>(null);
    // Все сообщения в чате
    const [messages, setMessages] = useState<IMessage[]>([]);
    // Сокет соединение
    const socket = useRef<WebSocket>();
    // Ссылка на чат, что бы скролить его автоматически
    const chatRef = useRef<HTMLDivElement | null>(null);
    // Активно ли сейчас сокет подключение
    const [connected, setConnected] = useState<boolean>(false);
    // Звук приходящего сообщения
    const [playSound] = useSound(newMessageSound, { volume: 0.1 });

    // Отправка данных по сокету
    const send = useCallback(
        (obj: object | string, encKey?: number) => {
            obj = encrypt(JSON.stringify(obj), encKey || key);
            socket.current?.send(stringToArrayBuffer(obj));
        },
        [key]
    );

    // Вход в чат по инвайту
    const loginByInvite = useCallback(
        (invite: string, encKey?: number) => {
            send({ event: wsEvents.LOGIN, invite }, encKey);
        },
        [send]
    );

    // Поиск инвайта в локальном хранилище
    const checkInvite = useCallback(
        (encKey: number) => {
            const invite = localStorage.getItem("invite");
            if (invite && user === null) loginByInvite(invite, encKey);
        },
        [user, loginByInvite]
    );

    // Успешный вход пользователя
    const loginSuccess = (user: IUser) => {
        setConnected(true);
        setUser(user);
        localStorage.setItem("invite", user.invite);
    };

    // Запрос всех сообщений в чате
    const getAllMessages = useCallback(() => {
        send({ event: wsEvents.GET_ALL_MESSAGES });
    }, [send]);

    const getMessage = useCallback(
        (data) => {
            setMessages((prev) => [...prev, data]);
            if (data.author.id !== user?.id) playSound();
            scrollToEnd();
        },
        [user, playSound]
    );

    // Отправка сообщения пользователя по сокету
    const sendMessage = (message: string) => {
        send({
            event: wsEvents.SEND_MESSAGE,
            message: {
                author: user,
                message,
            },
        });
    };

    // Событие удаления пользователя, если удалили нас, отключаемся
    const removeUser = useCallback(
        (id: number) => {
            // Если удалён текущий пользователь, закрываем чат
            if (id === user?.id) {
                setConnected(false);
                setUser(null);
            }
            // Обновление сообщений, что бы исчезли сообщения удалённого пользователя
            getAllMessages();
        },
        [getAllMessages, user]
    );

    // Скролинг чата в самый низ
    const scrollToEnd = () =>
        chatRef.current?.scroll(0, chatRef.current.scrollHeight);

    useEffect(() => {
        // Подключение по сокету при первой загрузке страницы
        socket.current = new WebSocket(process.env.REACT_APP_HOST || host);
        socket.current.binaryType = "arraybuffer";
    }, []);

    useEffect(() => {
        if (socket.current) {
            socket.current.onopen = () => {
                setConnected(true);

                // Запрос ключа
                socket.current?.send(
                    JSON.stringify({ event: wsEvents.GET_ENCRYPTION_KEY })
                );
            };

            socket.current.onmessage = ({ data }) => {
                if (typeof data === "string") {
                    data = JSON.parse(data);
                } else {
                    data = decrypt(arrayBufferToString(data), key);
                }

                switch (data?.event) {
                    case wsEvents.GET_ENCRYPTION_KEY:
                        setKey(+data.key);
                        return checkInvite(+data.key);
                    case wsEvents.UPDATE_ENCRYPTION_KEY:
                        return setKey(+data.key);
                    case wsEvents.LOGIN_SUCCESS:
                        return loginSuccess(data.user);
                    case wsEvents.GET_ALL_MESSAGES:
                        setMessages(data.messages);
                        return scrollToEnd();
                    case wsEvents.GET_MESSAGE:
                        return getMessage(data);
                    case wsEvents.REMOVE_ALL_MESSAGES:
                        return setMessages([]);
                    case wsEvents.REMOVE_USER:
                        return removeUser(data.id);

                    default:
                        return null;
                }
            };

            // Отключение сокета или ошибка
            socket.current.onclose = () => {
                setConnected(false);
                setUser(null);
            };

            socket.current.onerror = () => {
                setConnected(false);
                setUser(null);
            };
        }
    }, [user, key, checkInvite, removeUser, getMessage]);

    return (
        <div className="container" ref={chatRef}>
            {connected && user ? (
                <>
                    <div>
                        <MessagesList
                            connected={connected}
                            messages={messages}
                            userId={user.id}
                            getAllMessages={getAllMessages}
                            scrollToEnd={scrollToEnd}
                            ref={chatRef}
                        />
                        <MessageInput sendMessage={sendMessage} />
                    </div>
                    <Stickers sendMessage={sendMessage} />
                </>
            ) : (
                <Login loginByInvite={loginByInvite} />
            )}
        </div>
    );
};
