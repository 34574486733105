import React, {
    FC,
    useState,
    ChangeEvent,
    KeyboardEvent,
    MouseEvent,
} from "react";

import "./message-input.sass";

interface MessageInputProps {
    sendMessage: (message: string) => void;
}

export const MessageInput: FC<MessageInputProps> = ({ sendMessage }) => {
    // Значение инпута
    const [value, setValue] = useState<string>("");
    // Изменение текста в инпуте
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
        setValue(target.value);

    // Клик Enter для отправки сообщения
    const onKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
        if (key === "Enter") {
            if (value) {
                sendMessage(value);
                setValue("");
            }
        }
    };

    // Клик по кнопке для отправки сообщения
    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (value) {
            sendMessage(value);
            setValue("");
        }
    };

    return (
        <div className="message__input_wrapper">
            <div className="message__input_gradient"></div>
            <input
                type="text"
                className="message__input_input"
                placeholder="Write a message..."
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
            <button className="message__input_button" onClick={onClick} />
        </div>
    );
};
